//import { UserMetadata } from '@harmony/pcportal-shell-library';
import { getBearerToken, getPCToken, getFirstPartyToken } from './tokenHelper';
import { API_ROOT, NODE_ENV } from "../utilities/envVariables";
//import {localeConnector} from "@harmony/pcportal-shell-library";
import { getWoboSession, getImpersonationId } from "@emt/emt-wobo"
import { getLocale } from './sessionHelper';

export function getBaseUri(): string {
    // add it as parameter here
    //return `/dashboard/incentives/enrollment/api`;
    const baseUrl = API_ROOT;
    console.log(`api root is ${API_ROOT}, ${NODE_ENV}`);
    return `${baseUrl}`;
}

// function isWoboSession(): boolean {
//     if(window.location.href.indexOf("wobo") > -1){
//         return true;
//     }
//     return false;
// }

export async function getDefaultHeaders(needLocale: boolean, needPostHeaders: boolean): Promise<Headers> {
    const headers = new Headers();    
    headers.append("Authorization", `Bearer ${await getBearerToken()}`);
    headers.append("X-AppIdAccessToken", `${await getPCToken()}`);
    headers.append("ConstituentServiceFirstPartyToken", `${await getFirstPartyToken()}`);
    if(getWoboSession()){
        console.log(`This is wobo session`);
        getImpersonationId(window.location.href).then((impersonationId) => {
            headers.append("X-Impersonation-Id", impersonationId);
            console.log(`impersonationId is ${impersonationId}`);
        });
    }
    if(needLocale){
        headers.append("X-USER-LOCALE", getLocale());
    }
    if(needPostHeaders)
    {
        headers.append("Content-Type", "application/json");

    }
    //headers.append("Ms-Correlationid", PartnerCenterSessionHelper.correctlationId);
    
    return headers;
}

export async function postPutHeaders(): Promise<Headers> {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    //headers.append("Authorization", `Bearer ${await authzTokenInstance.getAuthzToken()}`);
    //headers.append("Ms-Correlationid", PartnerCenterSessionHelper.correctlationId);
    
    return headers;
}

export async function getDefaultRequestInit(needPostHeaders: boolean = false, needLocale: boolean = false): Promise<RequestInit> {
    const configInit: RequestInit = {
      headers: await getDefaultHeaders(needLocale, needPostHeaders),
    };
  
    return configInit;
} 