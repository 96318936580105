import {localeConnector} from "@harmony/pcportal-shell-library";

// import { PartnerCenterUrl } from "../utilities/envVariables";
// this set by the frontdoor team, we are only using them here.
const PartnerCenterUrl = window.location.hostname;
//const PartnerCenterUrl = "https://partner2.microsoft-tst.com"

export function getLocale(): string {
    let locale = localeConnector();
    if(locale){
        console.log(`locale is ${locale}`);
        console.log(`partner center url is ${PartnerCenterUrl}`);
        return locale;
    }
    return "en-us";
}

export const SessionHelper = {
    redirectToV1(path: string): void {
        window.location.href = `https://${PartnerCenterUrl}/${getLocale()}/dashboard/incentives/${path}`;
    },
};
