import { authContextConnector, AuthContextType, UserMetadata, authContextWorkspaceConnector } from '@harmony/pcportal-shell-library';


export async function getPCToken(): Promise<string | UserMetadata | undefined> {
    let token = await authContextConnector([AuthContextType.PartnerCenterApp]).then((tokenList) => {
        console.log("tokenList User: " + tokenList);
        return tokenList[AuthContextType.PartnerCenterApp];
    });
    console.log("User token: " + token);
    return token;
}

export async function getBearerToken(): Promise<string | UserMetadata | undefined> {
    /*
    let token: string | UserMetadata | undefined;
    authContextConnector([AuthContextType.PartnerApi]).then((tokenList) => {
        return tokenList[AuthContextType.PartnerApi];
    }).then((token2) => {
        console.log("Bearer token: " + token);
        token = token2;
    });
    return token;
    */
    //console.log("Bearer token: " + token);
    //return token;


    let token = await authContextConnector([AuthContextType.PartnerApi]).then((tokenList) => {
        console.log("tokenList Bearer: " + tokenList);
        return tokenList[AuthContextType.PartnerApi];
    });
    console.log("Bearerr token: " + token);
    //await getFirstPartyToken();
    return token;
}

export function getFirstPartyToken(): Promise<string | UserMetadata | undefined> {
    return authContextWorkspaceConnector("xjayl89",["ConstituentServiceFirstPartyToken"]).then((tokenList) => {
        if (tokenList) {
            console.log("ConstituentServiceFirstPartyToken: "+JSON.parse( JSON.stringify(tokenList["ConstituentServiceFirstPartyToken"]))["accessToken"]);
            return JSON.parse( JSON.stringify(tokenList["ConstituentServiceFirstPartyToken"]))["accessToken"];
        } else {
            console.log("1p tokens: undefined");
            return undefined;
        }
    });
}

export function getPCTokenSync(): string | UserMetadata | undefined {
    let token: string | UserMetadata | undefined;
    authContextConnector([AuthContextType.PartnerCenterApp]).then((tokenList) => {
        token = tokenList[AuthContextType.PartnerCenterApp];
    });
    console.log("PC token: " + token);
    return token;
}

export function getBearerTokenSync(): string | UserMetadata | undefined {
    let token: string | UserMetadata | undefined;
    authContextConnector([AuthContextType.PartnerApi]).then((tokenList) => {
        token = tokenList[AuthContextType.PartnerApi];
    });
    console.log("Bearer token: " + token);
    return token;
}