import { Environment } from "./environment";
import { PartnerId } from "../models/model";
import { getWoboSession } from "@emt/emt-wobo";

export class RoutesHelper {
    static getBankAndTaxRoute(activeEnrollmentRow: string|null = null, prepopulateDefault: boolean = false): string {
        if (activeEnrollmentRow) {
            return `/dashboard/payee/profiles/partner/assign?activeEnrollmentRow=${activeEnrollmentRow}&prepopulateDefault=${prepopulateDefault}`
        }
        return "/dashboard/payee/profiles/partner/assign";
    }

    static getInsightsProgramPageLink(programCode: string) {
        switch(programCode) {
            //AGI
            case "16810a78-79a4-44c7-be3a-acaee3d0b27c":
                return "/dashboard/payouts/reports/agiprogram?program=" + programCode;            
            default:
                return `/dashboard/v2/earnings/earnings?program=${programCode}`;
        }
    }

    static getModernOfferingLink(offeringId: string): string {
        if (Environment.isModern()) {
            if (Environment.hrefContains("summary")) {
                return `/dashboard/incentives/enrollment/summary/offerings/${offeringId}`;
            } else {
                return `/dashboard/incentives/enrollment/modern/web/offerings/${offeringId}`;
            }
        } else {
            return `/dashboard/incentives/enrollment/web/offerings/${offeringId}`;
        }
    }

    static getCoopClaimRoute(offeringId: string, usagePeriodCode: string, partnerId: PartnerId):string {
        const mpnNumberQueryString = `?mpnNumber=${partnerId.value}`;
        if (Environment.isModern()) {
            return `/dashboard/incentives/claims/coop/pc/offerings/${offeringId}/usageperiod/${usagePeriodCode}${mpnNumberQueryString}`;
        } else {
            return `/dashboard/incentives/claims/coop/offerings/${offeringId}/usageperiod/${usagePeriodCode}${mpnNumberQueryString}`;
        }
    }

    static getOsaClaimRoute(offeringId: string, partnerId: PartnerId):string {
        const mpnNumberQueryString = `?mpnNumber=${partnerId.value}`;
        if (Environment.isModern()) {
            return `/dashboard/incentives/claims/osa/pc/offerings/${offeringId}${mpnNumberQueryString}`;
        } else {
            return `/dashboard/incentives/claims/osa/offerings/${offeringId}${mpnNumberQueryString}`;
        }
    }

    //Append wobo parameter on url if theres an existing wobo session.
    static addParamIfWoboSession(url: string): string {
        const woboSession = getWoboSession();

        if(woboSession){
            url = url + (url.indexOf("?") < 0 ? "?" : "&") + `wobo=${woboSession}`;
        }
        return url;
    }
}
